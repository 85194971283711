import { ApiService, HttpMethod } from '../_common/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StrikeGuardDatum } from './models/strike-guard-datum.model';

@Injectable({
    providedIn: 'root'
})
export class StrikeGuardApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getEntryById(sgId: string): Observable<StrikeGuardDatum> {
        const x = StrikeGuardApiService.getData(sgId);
        console.log(x);

        return this.apiService.apiRequest<any>(StrikeGuardApiService.getData(sgId), HttpMethod.Get)
            .pipe(map(response => new StrikeGuardDatum(response)));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}strikeGuard`; }

    private static getData(sgId: string): string { return `${StrikeGuardApiService.baseUrl}/${sgId}`; }
}
