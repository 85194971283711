import WizardIntegration = ToroEnums.WizardIntegration;
import WidgetType = ToroEnums.WidgetType;

import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { finalize, switchMap, take } from 'rxjs/operators';
import { forkJoin, Observable, timeout } from 'rxjs';
import { AssetTrackingManagerService } from '../../../../api/asset-tracking/asset-tracking-manager.service';
import { AuthManagerService } from '../../../../api/auth/auth-manager.service';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { CalAmpDevice } from '../../../../api/cal-amp/models/cal-amp-device.model';
import { DashUserInfo } from '../../../../api/dash-user/models/dash-user-info.model';
import { DashUserManagerService } from '../../../../api/dash-user/dash-user-manager.service';
import { environment } from '../../../../../environments/environment';
import { EzLocatorManagerService } from '../../../../api/ez-locator/ez-locator-manager.service';
import { IntegrationStep } from '../models/integration-step.model';
import { LocalCacheService } from '../../../../common/services/local-cache.service';
import { MyTurfManagerService } from '../../../../api/my-turf/my-turf-manager.service';
import { MyTurfPartsOrder } from '../../../../api/my-turf/models/my-turf-parts-order.model';
import { PlaybooksLinkUrl } from '../../../../api/playbooks/models/playbooks-link-url.model';
import { PlaybooksManagerService } from '../../../../api/playbooks/playbooks-manager.service';
import { SoilScoutManagerService } from '../../../../api/soil-scout/soil-scout-manager.service';
import { SoilScoutSite } from '../../../../api/soil-scout/models/soil-scout-site.model';
import { TaskTrackerManagerService } from '../../../../api/task-tracker/task-tracker-manager.service';
import { ToroEnums } from '../../../../common/enumerations/toro.enums';
import { WizardSettings } from '../models/wizard-settings.model';

@Component({
    selector: 'toro-step-integrations',
    templateUrl: './step-integrations.component.html',
    styleUrls: ['./step-integrations.component.less']
})
export class StepIntegrationsComponent implements OnInit {
    @HostBinding('class') class = 'toro-step-integrations';

    WidgetType = WidgetType;
    WizardIntegration = WizardIntegration;

    private readonly integrationConnectionsCount = 9;

    @Output() canConnect = new EventEmitter<boolean>();
    @Output() connectionCheckComplete = new EventEmitter<boolean>();

    // Two-way bound properties

    @Output() isDoSetupChange = new EventEmitter<boolean>();

    _isDoSetup = false;
    @Input() set isDoSetup(value: boolean) {
        this._isDoSetup = value;
        this.isDoSetupChange.emit(value);
    }

    @Output() selectedWidgetsChange = new EventEmitter<{ type: WidgetType, isSelected: boolean }[]>();

    private _selectedWidgets: { type: WidgetType, isSelected }[];
    @Input() set selectedWidgets(value: { type: WidgetType, isSelected }[]) {
        this._selectedWidgets = value;
        this.selectedWidgetsChange.emit(this._selectedWidgets);
    }

    get selectedWidgets(): { type: WidgetType, isSelected }[] {
        return this._selectedWidgets;
    }

    private _isConnectionCheckComplete = false;
    @Input() set isConnectionCheckComplete(value: boolean) {
        this._isConnectionCheckComplete = value;
    }

    get isConnectionCheckComplete(): boolean {
        return this._isConnectionCheckComplete;
    }

    // Standard Properties

    integrations: IntegrationStep[] = [];
    selectedWidgetType: WidgetType;
    configuredIntegrations: WizardIntegration[] = [];
    settings: WizardSettings;
    isBusy = true;
    spinnerText = 'SPECIAL_MSG.CHECKING_INTEGRATION_CONNECTIONS'

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private assetTrackingManager: AssetTrackingManagerService,
                private authManager: AuthManagerService,
                private broadcastService: BroadcastService,
                private dashUserManager: DashUserManagerService,
                private ezLocatorManager: EzLocatorManagerService,
                private localCacheService: LocalCacheService,
                private myTurfManager: MyTurfManagerService,
                private playbooksManager: PlaybooksManagerService,
                private soilScoutManager: SoilScoutManagerService,
                private taskTrackerManager: TaskTrackerManagerService
    ) { }

    ngOnInit(): void {
        this.settings = this.localCacheService.wizardSettings ?? new WizardSettings();

        this.configureIntegrations();

        if (!this.isConnectionCheckComplete) setTimeout(() => this.checkForIntegrationConnections(), 0);
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onDoSetup(integration: IntegrationStep) {
        // this.canConnect = false;
        this.selectedWidgetType = integration.widgetType;
        this.isDoSetup = true;
    }

    onCanConnect(value: boolean) {
        this.canConnect.emit(value);
    }

    onConfigured(integration: WizardIntegration) {
        switch (integration) {
            // case WizardIntegration.MyTurf:
            // this.localCacheService.wizardActiveWidgets = this.selectedWidgets;
            // break;
            case WizardIntegration.AssetTracking:
            case WizardIntegration.Playbooks:
            case WizardIntegration.TaskTracker:
            case WizardIntegration.PerryWeather:
            case WizardIntegration.Spectrum:
            case WizardIntegration.StrikeGuard:
                this.localCacheService.wizardSettings = this.integrations[integration].settings;
                this.broadcastService.setupCompleteIntegration.next(null);
                break;
            case WizardIntegration.EzLocator:
            case WizardIntegration.SoilScout:
                this.broadcastService.setupCompleteIntegration.next(null);
                break;
        }

        this.localCacheService.wizardActiveWidgets = this.selectedWidgets;

        // Mark the current integration as configured.
        // TODO: Validate configuration (perhaps w/in the specific integrations).
        this.integrations[integration].isConfigured = true;

        // Store list of configured integrations in local storage in case we need to temporally leave the app.
        if (!this.configuredIntegrations.includes(integration)) this.configuredIntegrations.push(integration);
        this.localCacheService.wizardConfiguredIntegrations = this.configuredIntegrations;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    // NOTE: Order needs to align with WizardIntegration enum.
    private configureIntegrations() {
        this.configuredIntegrations = this.localCacheService.wizardConfiguredIntegrations ?? [];

        this.integrations = [
            new IntegrationStep({
                widgetType: WidgetType.MyTurfMaintenance,
                headerImage: '/assets/images/wizard/myTurf.png',
                headerImageHeightInPixels: 40,
                description: 'SPECIAL_MSG.WIZARD_MY_TURF_INTEGRATION_DESC',
                linkText: 'STRINGS.ENROLL',
                linkUrl: environment.myTurfUrl,
                isConfigured: this.configuredIntegrations.includes(WizardIntegration.MyTurf)
            }),
            new IntegrationStep({
                widgetType: WidgetType.PerryWeather,
                headerImage: '/assets/images/wizard/perryWeather.png',
                headerImageHeightInPixels: 40,
                description: 'SPECIAL_MSG.WIZARD_PERRY_WEATHER_INTEGRATION_DESC',
                linkText: 'STRINGS.LEARN_MORE',
                linkUrl: environment.perryWeatherUrl,
                isConfigured: this.configuredIntegrations.includes(WizardIntegration.PerryWeather),
                settings: this.settings
            }),
            new IntegrationStep({
                widgetType: WidgetType.EquipmentTracking,
                headerImage: '/assets/images/wizard/assetTracking.png',
                headerImageHeightInPixels: 40,
                description: 'SPECIAL_MSG.WIZARD_ASSET_TRACKING_INTEGRATION_DESC',
                linkText: 'STRINGS.LEARN_MORE',
                linkUrl: environment.assetTrackingSiteUrl,
                isConfigured: this.configuredIntegrations.includes(WizardIntegration.AssetTracking),
                settings: this.settings
            }),
            new IntegrationStep({
                widgetType: WidgetType.Playbooks,
                headerImage: '/assets/images/wizard/playbooks.png',
                headerImageHeightInPixels: 40,
                description: 'SPECIAL_MSG.WIZARD_PLAYBOOKS_INTEGRATION_DESC',
                linkText: 'STRINGS.LEARN_MORE',
                linkUrl: 'https://goplaybooks.com/',
                isConfigured: this.configuredIntegrations.includes(WizardIntegration.Playbooks),
                settings: this.settings
            }),
            new IntegrationStep({
                widgetType: WidgetType.TaskTrackerSafety,
                headerImage: '/assets/images/wizard/taskTracker.png',
                headerImageHeightInPixels: 40,
                description: 'SPECIAL_MSG.WIZARD_TASK_TRACKER_INTEGRATION_DESC',
                linkText: 'STRINGS.LEARN_MORE',
                linkUrl: 'https://www.advancedscoreboard.com/',
                isConfigured: this.configuredIntegrations.includes(WizardIntegration.TaskTracker),
                settings: this.settings
            }),
            new IntegrationStep({
                widgetType: WidgetType.EzLocator,
                headerImage: '/assets/images/wizard/ezLocator.png',
                headerImageHeightInPixels: 40,
                description: 'SPECIAL_MSG.WIZARD_EZ_LOCATOR_INTEGRATION_DESC',
                linkText: 'STRINGS.LEARN_MORE',
                linkUrl: 'https://www.ezlocator.com/',
                isConfigured: this.configuredIntegrations.includes(WizardIntegration.EzLocator)
            }),
            new IntegrationStep({
                widgetType: WidgetType.SoilScoutMoisture,
                headerImage: '/assets/images/wizard/soil-scout-logo-full.png',
                headerImageHeightInPixels: 47,
                description: 'SPECIAL_MSG.WIZARD_SOIL_SCOUT_DESC',
                linkText: 'STRINGS.LEARN_MORE',
                linkUrl: 'https://soilscout.com/',
                isConfigured: this.configuredIntegrations.includes(WizardIntegration.SoilScout)
            }),
            new IntegrationStep({
                widgetType: WidgetType.SpectrumMoisture,
                headerImage: '/assets/images/wizard/spectrum-logo.png',
                headerImageHeightInPixels: 44,
                description: 'SPECIAL_MSG.WIZARD_SPECTRUM_INTEGRATION_DESC',
                linkText: 'STRINGS.LEARN_MORE',
                linkUrl: environment.spectrumWizardUrl,
                isConfigured: this.configuredIntegrations.includes(WizardIntegration.Spectrum),
                settings: this.settings
            }),
            new IntegrationStep({
                widgetType: WidgetType.StrikeGuard,
                headerImage: '/assets/images/strike-guard-logo.png',
                headerImageHeightInPixels: 40,
                description: 'SPECIAL_MSG.WIZARD_STRIKE_GUARD_INTEGRATION_DESC',
                linkText: 'STRINGS.LEARN_MORE',
                linkUrl: environment.strikeGuardSiteUrl,
                isConfigured: this.configuredIntegrations.includes(WizardIntegration.StrikeGuard),
                settings: this.settings
            }),
        ]
    }

    private checkForIntegrationConnections() {
        let checkCount = 0;

        // myTurf ==============================================================================================================================================
        this.myTurfManager.getPartsOrders()
            .pipe(
                take(1),
                finalize(() => this.setConnectionCheckStatus(++checkCount))
            )
            .subscribe((response: MyTurfPartsOrder[]) => {
                // Select both of the myTurf widgets. The assumption is that since we are configuring them, the user will want them.
                // They can still disable them on the enable/disable pane.
                // this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.MyTurfMaintenance, isEnabled: true });
                // this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.MyTurfOrders, isEnabled: true });
                this.onConfigured(WizardIntegration.MyTurf);
            });

        // ezLocator ===========================================================================================================================================
        this.ezLocatorManager.getAccessId(this.dashUserManager.userEmail)
            .pipe(
                take(1),
                finalize(() => this.setConnectionCheckStatus(++checkCount))
            )
            .subscribe(() => {
                // this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.EzLocator, isEnabled: true });
                this.onConfigured(WizardIntegration.EzLocator);
            })

        // Asset Tracking ======================================================================================================================================
        this.dashUserManager.getDashUserInfo()
            .pipe(
                take(1),
                switchMap((dashUserInfo: DashUserInfo) => {
                    this.settings.assetTrackingAccountId = dashUserInfo?.preferences?.calAmpAccountId;

                    return this.assetTrackingManager.getDeviceList(dashUserInfo?.preferences?.calAmpAccountId, false)
                        .pipe(
                            take(1),
                            timeout(10000),
                            finalize(() => this.setConnectionCheckStatus(++checkCount))
                        )
                })
            )
            .subscribe({
                next: (devices: CalAmpDevice[]) => {
                    // this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.EquipmentTracking, isEnabled: true });
                    this.onConfigured(WizardIntegration.AssetTracking);
                },
                error: err => this.settings.assetTrackingAccountId = null
            });

        // Playbooks ===========================================================================================================================================
        const playbooksCourseId = this.authManager.dashAuthenticatedUser?.playbooksCourseId;
        if (playbooksCourseId == null || playbooksCourseId === '') {
            this.setConnectionCheckStatus(++checkCount);
        } else {
            this.playbooksManager.getLinkUrls()
                .pipe(
                    take(1),
                    finalize(() => this.setConnectionCheckStatus(++checkCount))
                )
                .subscribe((result: PlaybooksLinkUrl[]) => {
                    // this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.Playbooks, isEnabled: true });
                    this.settings.playbooksCourseId = playbooksCourseId;
                    this.onConfigured(WizardIntegration.Playbooks);
                })
        }

        // Task Tracker ========================================================================================================================================
        const sources: Observable<any>[] = [
            this.taskTrackerManager.getActiveCompanies(false).pipe(take(1)),
            this.dashUserManager.getDashUserInfo().pipe(take(1)),
        ];

        forkJoin(sources)
            .pipe(finalize(() => this.setConnectionCheckStatus(++checkCount)))
            .subscribe(([companies, dashUserInfo]) => {
                const dashUserPrefs = dashUserInfo.preferences;
                if (dashUserPrefs && dashUserPrefs.taskTrackerApiKey != null && companies && companies.length > 0) {
                    const company = companies.find(c => c.uuid === dashUserPrefs.taskTrackerApiKey);
                    const companyId = company?.id;
                    if (companyId != null) {
                        // this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.TaskTrackerSafety, isEnabled: true });
                        // this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.TaskTrackerLaborSummary, isEnabled: true });
                        // this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.TaskTrackerDailyMeasurements, isEnabled: true });

                        this.settings.taskTrackerApiKey = dashUserPrefs.taskTrackerApiKey;
                        this.onConfigured(WizardIntegration.TaskTracker);
                    }
                }
            });

        // Perry Weather =======================================================================================================================================
        this.dashUserManager.getDashUserInfo()
            .pipe(
                take(1),
                finalize(() => this.setConnectionCheckStatus(++checkCount))
            )
            .subscribe((dashUserInfo: DashUserInfo) => {
                const dashUserPrefs = dashUserInfo.preferences;
                if (dashUserPrefs && dashUserPrefs.pwIntegrationKey != null && dashUserPrefs.pwIntegrationKey != '') {
                    this.settings.perryWeatherIntegrationKey = dashUserPrefs.pwIntegrationKey;
                    this.onConfigured(WizardIntegration.PerryWeather);
                    return;
                }
            });

        // Strike Guard =======================================================================================================================================
        this.dashUserManager.getDashUserInfo()
            .pipe(
                take(1),
                finalize(() => this.setConnectionCheckStatus(++checkCount))
            )
            .subscribe((dashUserInfo: DashUserInfo) => {
                const dashUserPrefs = dashUserInfo.preferences;
                if (dashUserPrefs && dashUserPrefs.strikeGuardID != null && dashUserPrefs.strikeGuardID != '') {
                    this.settings.strikeGuardID = dashUserPrefs.strikeGuardID;
                    this.onConfigured(WizardIntegration.StrikeGuard);
                    return;
                }
            });

        // Spectrum ============================================================================================================================================
        this.dashUserManager.getDashUserInfo()
            .pipe(
                take(1),
                finalize(() => this.setConnectionCheckStatus(++checkCount))
            )
            .subscribe((dashUserInfo: DashUserInfo) => {
                const dashUserPrefs = dashUserInfo.preferences;
                if (dashUserPrefs && dashUserPrefs.spectrumApiKey != null && dashUserPrefs.spectrumApiKey != '') {
                    this.settings.spectrumApiKey = dashUserPrefs.spectrumApiKey;
                    this.onConfigured(WizardIntegration.Spectrum);
                    return;
                }
            });

        // TODO: NOTE - This check uses a return statement to bail out. Make sure this check is the last one or change the logic.
        // Soil Scout ==========================================================================================================================================
        const refreshToken = this.localCacheService.soilScoutRefreshToken;
        if (refreshToken == null || refreshToken == 'null' || refreshToken == '') {
            this.setConnectionCheckStatus(++checkCount);
            return;
        }

        this.soilScoutManager.getSites()
            .pipe(
                take(1),
                finalize(() => this.setConnectionCheckStatus(++checkCount))
            )
            .subscribe((sites: SoilScoutSite[]) => this.onConfigured(WizardIntegration.SoilScout));
    }

    private setConnectionCheckStatus(checkCount: number) {
        if (checkCount === this.integrationConnectionsCount) {
            this.isBusy = false;
            this.connectionCheckComplete.next(true);
        }
    }

}
