import WizardIntegration = ToroEnums.WizardIntegration;
import WidgetType = ToroEnums.WidgetType;

import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { StrikeGuardManagerService } from '../../../../../api/strike-guard/strike-guard-manager.service';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { WizardSettings } from '../../models/wizard-settings.model';

@UntilDestroy()
@Component({
    selector: 'toro-strike-guard-integration',
    templateUrl: './strike-guard-integration.component.html',
    styleUrls: ['./strike-guard-integration.component.less']
})
export class StrikeGuardIntegrationComponent implements OnInit {
    @HostBinding('class') class = 'wiz-integration-component perry-weather-integration';

    @Output() canConnect = new EventEmitter<boolean>();
    @Output() configured = new EventEmitter<WizardIntegration>();
    @Output() settingsChange = new EventEmitter<WizardSettings>();

    @Input() settings: WizardSettings;

    readonly stepsCount = 1;
    private elements: any;

    isBusy = false;
    showError = false;
    spinnerText = 'CASE_SENSITIVE.VERIFYING_INTEGRATION_KEY'
    strikeGuardId: string;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private dashUserManager: DashUserManagerService,
                private strikeGuardManager: StrikeGuardManagerService
    ) { }

    ngOnInit(): void {
        this.broadcastService.setupDoConnect
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.verifyConfiguration();
            });

        // We want to modify the behavior of the p-inputNumber component to allow us to assess the controls
        // value after each key press. To do that, we will search the dom for our unique container component
        // and then find out "common" p-inputNumber class element. There will only be one in our container.
        // Once we have our element, we will assign the keyup event handler.
        const container = document.getElementsByClassName('wic-perry-weather-info');
        this.elements = container[0].getElementsByClassName('perry-weather-integration-key');

        if (this.elements.length != 1) throw new Error('There should be one and only one input element here.');
        this.elements[0].addEventListener('keyup', this.keyUp.bind(this))

        if (this.settings && this.settings.strikeGuardID != null) {
            this.strikeGuardId = this.settings.strikeGuardID;
            this.canConnect.next(true);
        }
    }

    ngOnDestroy() {
        this.elements[0].removeEventListener('keyup', this.keyUp.bind(this))
        this.canConnect.emit(false);
    }

    // =========================================================================================================================================================
    // Event Handler
    // =========================================================================================================================================================

    private keyUp(event) {
        this.canConnect.emit(event.target.value.length == 36 && !event.target.value.includes('_'));
    }

    onPasteApiKey(event: ClipboardEvent) {
        const pastedText = event?.clipboardData?.getData('text');
        this.canConnect.emit(pastedText && pastedText.length == 36 && !pastedText.includes('_'));
    }

    onCutApiKey(event: ClipboardEvent) {
        const pastedText = event?.clipboardData?.getData('text');
        this.canConnect.emit(pastedText && pastedText.length == 36 && !pastedText.includes('_'));
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private verifyConfiguration() {
        this.showError = false;
        this.isBusy = true;

        const sources: Observable<any>[] = [
            this.dashUserManager.getDashUserInfo().pipe(take(1)),
            this.strikeGuardManager.getEntryById(this.strikeGuardId)
        ];

        forkJoin(sources)
            .pipe(
                take(1),
                finalize(() => this.isBusy = false)
            )
            .subscribe({
                next: ([user]) => {
                    this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.StrikeGuard, isEnabled: true });

                    const userPrefs = user.preferences;
                    userPrefs.strikeGuardID = this.strikeGuardId;
                    this.dashUserManager.updateDashUserPreferences(userPrefs)
                        .subscribe(() => {
                            this.settings.strikeGuardID = this.strikeGuardId;
                            this.settingsChange.next(this.settings);
                            this.configured.next(WizardIntegration.StrikeGuard);

                            // Notify Strike Guard Widget. This will only matter if the Strike Guard widget is already being displayed.
                            this.broadcastService.setupStrikeGuardIdChanged.next(this.strikeGuardId);
                        })
                },
                error: (err: any) => {
                    const error = err?.error?.Error_1[0];
                    if (error && error.toLowerCase().includes('no entry found')) {
                        this.showError = true;
                    }
                }
            })

    }
}
