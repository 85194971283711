<p-dialog appendTo="body" [visible]="true" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%'}">
        <p-header>{{'CASE_SENSITIVE.ENTER_STRIKE_GUARD_ID' | translate }}</p-header>

    <div id="tt-api-key-dialog-container">
        <span>{{'CASE_SENSITIVE.STRIKE_GUARD_ID' | translate }}</span>
        <p-inputMask
                [(ngModel)]="apiKey"
                mask="********-****-****-****-************"
                characterPattern="[a-fA-F0-9]"
                [autoFocus]="true"
                [size]="40"
                [placeholder]="'CASE_SENSITIVE.STRIKE_GUARD_ID' | translate">
        </p-inputMask>
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CANCEL' | translate" class="ui-button-secondary" (onClick)="onCancelDialog()"></p-button>
        <p-button type="button" [label]="'STRINGS.SAVE' | translate" (onClick)="onSetApiKey()"></p-button>
    </p-footer>
</p-dialog>
