import * as moment from 'moment';
import { StrikeGuardDatum } from '../../api/strike-guard/models/strike-guard-datum.model';
import { ToroEnums } from '../../common/enumerations/toro.enums';
import StrikeGuardStatus = ToroEnums.StrikeGuardStatus;

export class StrikeGuardDemoData {

    static get strikeGuardData(): StrikeGuardDatum {
        return new StrikeGuardDatum({
            sgId: '12345',
            locationName: 'Toro Links',
            currentMode: StrikeGuardStatus.Caution,
            nextModeChange: moment.utc().add(50, 'minutes').toDate(),
            lastContactUtc: moment.utc().toDate()
        });
    }

}
