import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import StrikeGuardStatus = ToroEnums.StrikeGuardStatus;
import StrikeGuardStatusColor = ToroEnums.StrikeGuardStatusColor;

import * as moment from 'moment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserInfo } from '../../../../../api/dash-user/models/dash-user-info.model';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DashUserPreferences } from '../../../../../api/dash-user/models/dash-user-preferences.model';
import { DatePipe } from '@angular/common';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { environment } from '../../../../../../environments/environment';
import { StrikeGuardDatum } from '../../../../../api/strike-guard/models/strike-guard-datum.model';
import { StrikeGuardManagerService } from '../../../../../api/strike-guard/strike-guard-manager.service';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../../toro-dashboard-widget';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { ToroUtils } from '../../../../../common/utils/_toro.utils';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from '@ngneat/until-destroy';

@Component({
    selector: 'toro-widget-strike-guard',
    templateUrl: './widget-strike-guard.component.html',
    styleUrls: ['./widget-strike-guard.component.less']
})
export class WidgetStrikeGuardComponent extends ToroDashboardWidget implements OnInit, OnDestroy {

    iconColor = 'black';
    title = 'WIDGET.STRIKE_GUARD';

    StrikeGuardStatus = StrikeGuardStatus;

    protected status: StrikeGuardStatus = StrikeGuardStatus.Caution;
    protected backgroundColor: string = 'white';
    protected statusColor: string = 'black';
    protected statusText: string;
    protected statusIcon: any = ['fas', 'bolt'];
    protected messageText: string = '';
    protected modeText: string = 'CASE_SENSITIVE.NEXT_MODE_CHANGE'
    protected modeTime: string = '--'
    protected isSoloBanner = false;
    protected strikeGuardDatum: StrikeGuardDatum;
    protected deltaMins: string;
    protected isValidCompanyApiKeySet = false;
    protected isApiKeyDialogDisplayed = false;
    protected newStrikeGuardID: string;
    protected dashUserPrefs: DashUserPreferences;
    protected spinnerText = '';

    private _strikeGuardID = null;
    set strikeGuardID(value: string) {
        this._strikeGuardID = (value != null && value !== '') ? value : null;
    }

    get strikeGuardID(): string {
        return this._strikeGuardID;
    }

    private deltaIntervalRef: any;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                private datePipe: DatePipe,
                protected deviceManager: DeviceManagerService,
                private strikeGuardManager: StrikeGuardManagerService,
                protected translateService: TranslateService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.broadcastService.setupStrikeGuardIdChanged
            .pipe(untilDestroyed(this))
            .subscribe((integrationKey: string) => {
                this.getWidgetData(true);
            });

    }

    ngOnDestroy(): void {
        clearInterval(this.deltaIntervalRef);
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    get analyticsWidgetName(): string {
        return AnalyticsEvent.StrikeGuardWidgetName;
    }

    protected getWidgetData(isManualRefresh) {
        if (isManualRefresh) { this.isBusy = true; }

        this.dashUserManager.getDashUserInfo()
            .pipe(take(1))
            .subscribe((dashUserInfo: DashUserInfo) => {
                this.dashUserPrefs = dashUserInfo.preferences;
                if (!this.dashUserPrefs || this.dashUserPrefs.strikeGuardID == null) {
                    this.setNoAccountDisplay();
                    return;
                }

                this.strikeGuardID = this.dashUserPrefs.strikeGuardID;

                this.strikeGuardManager.getEntryById(this.strikeGuardID)
                    .pipe(finalize(() => { this.isBusy = false}))
                    .subscribe({
                        next: (datum: StrikeGuardDatum) => {
                            this.strikeGuardDatum = datum;
                            this.clearIsUnableToFetchData();
                            this.lastUpdateTimestamp = datum.lastContactUtc;
                            this.setDisplayValues();
                        },
                        error: err => {
                            this.setIsUnableToFetchData('SPECIAL_MSG.VERIFY_STRIKE_GUARD_ID');
                            this.dashMessageService.showWidgetDataFetchErrorMessage(this.title);
                        }
                    })
            })
    }

    protected setWidgetMenu() {
        super.setWidgetMenu();

        this.widgetMenuItems.unshift(
            {
                label: ToroUtils.Translate.instant('CASE_SENSITIVE.ENTER_STRIKE_GUARD_ID'),
                icon: 'pi pi-fw pi-key',
                command: this.showApiKeyDialog.bind(this)
            },
            { separator: true }
        );
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onNavigateToSite() {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_STRIKE_GUARD' });
        setTimeout(() => window.open(environment.strikeGuardSiteUrl, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }

    onSetApiKey(apiKey: string) {
        this.isApiKeyDialogDisplayed = false;

        // TODO: Temporary?
        if (apiKey != null) {
            this.newStrikeGuardID = apiKey;
        }

        // If the Course Id was not changed, don't do anything.
        if (this.strikeGuardID === this.newStrikeGuardID) { return; }

        this.strikeGuardID = this.newStrikeGuardID;
        this.dashUserPrefs.strikeGuardID = this.strikeGuardID;

        this.dashUserManager.updateDashUserPreferences(this.dashUserPrefs)
            .subscribe({
                next: () => {
                    if (this.strikeGuardID != null && this.strikeGuardID != '') {
                        this.isBusy = true;
                        this.spinnerText = `${this.translateService.instant('STRINGS.RETRIEVING_DATA_FOR_NEW_SG_ID')}...`;
                        this.getWidgetData(true);
                    } else {
                        this.setNoAccountDisplay();
                    }

                    const eventDetails = `Strike Guard ID: ${this.newStrikeGuardID}`
                    this.analyticsService.widgetEvent(AnalyticsEvent.StrikeGuardWidgetSaveApiKeyDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
                },
                error: () => {
                    this.resetBusy();
                    this.dashMessageService.showGenericSaveErrorMessage();
                }
            });
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setDisplayValues() {
        this.setStatusValues();
    }

    private setStatusValues(): void {
        clearInterval(this.deltaIntervalRef);

        this.modeText = 'CASE_SENSITIVE.NEXT_MODE_CHANGE'
        this.statusIcon = ['fas', 'bolt'];
        this.isSoloBanner = false;
        this.statusColor = 'black';
        this.deltaMins = '--';

        if (!this.isDateBeforeNow(this.strikeGuardDatum?.nextModeChange)) {
            this.modeTime = this.datePipe.transform(this.strikeGuardDatum?.nextModeChange, 'h:mm a') || '--';
        } else {
            this.modeTime = '--:-- --';
        }

        switch (this.strikeGuardDatum?.currentMode) {
            case StrikeGuardStatus.Alarm:
                this.statusColor = 'white';
                this.backgroundColor = StrikeGuardStatusColor.Alarm;
                this.statusText = 'CASE_SENSITIVE.LIGHTNING_ALARM';
                this.messageText = 'SPECIAL_MSG.MINUTES_TO_WARNING_MODE';
                this.modeText = 'CASE_SENSITIVE.NEXT_MODE_CHANGE'
                this.setDeltaMins();
                break;

            case StrikeGuardStatus.Warning:
                this.backgroundColor = StrikeGuardStatusColor.Warning;
                this.statusText = 'CASE_SENSITIVE.LIGHTNING_WARNING';
                this.messageText = 'SPECIAL_MSG.MINUTES_TO_CAUTION_MODE';
                this.modeText = 'CASE_SENSITIVE.NEXT_MODE_CHANGE'
                this.setDeltaMins();
                break;

            case StrikeGuardStatus.Caution:
                this.backgroundColor = StrikeGuardStatusColor.Caution;
                this.statusText = 'CASE_SENSITIVE.LIGHTNING_CAUTION';
                this.messageText = 'SPECIAL_MSG.MINUTES_TO_LIGHTNING_DETECTED';
                this.setDeltaMins();
                break;

            case StrikeGuardStatus.NoLightingDetected:
                this.statusColor = 'white';
                this.backgroundColor = StrikeGuardStatusColor.NoLightingDetected;
                this.statusText = 'CASE_SENSITIVE.NO_LIGHTNING_DETECTED';
                this.messageText = null;
                this.modeText = null;
                this.isSoloBanner = true;
                break;

            case StrikeGuardStatus.Error:
                this.statusColor = 'red';
                this.backgroundColor = StrikeGuardStatusColor.Error;
                this.statusText = 'STRINGS.FAILURE';
                this.messageText = null;
                this.modeText = null;
                this.isSoloBanner = true;
                break;

            case StrikeGuardStatus.Offline:
                this.backgroundColor = StrikeGuardStatusColor.Offline;
                this.statusText = 'STRINGS.OFFLINE';
                this.statusIcon = ['fas', 'times-circle']
                this.messageText = null;
                // this.modeText = 'STRINGS.OFFLINE_FOR';
                this.modeText = null;
                this.isSoloBanner = true;
                this.modeTime = null;
                break;
        }
    }

    private setDeltaMins() {
        if (this.strikeGuardDatum?.nextModeChange != null) {
            const now = moment.utc();
            const mDate = moment.utc(this.strikeGuardDatum?.nextModeChange);
            this.deltaMins = Math.ceil(mDate.diff(now, 'minutes', true)).toString();

            if (+this.deltaMins < 0) { this.deltaMins = '--'}

            this.startDeltaInterval();
        }
    }

    private startDeltaInterval() {
        clearInterval(this.deltaIntervalRef);

        this.deltaIntervalRef = setInterval(() => {
            this.setDeltaMins();
        }, 30 * 1000)
    }

    private showApiKeyDialog() {
        this.newStrikeGuardID = this.strikeGuardID;
        this.isApiKeyDialogDisplayed = true;

        const eventDetails = `API Key: ${this.strikeGuardID}`;
        this.analyticsService.widgetEvent(AnalyticsEvent.StrikeGuardWidgetShowApiKeyDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
    }

    protected resetBusy() {
        this.isBusy = false;
        this.spinnerText = null;
    }

    private setNoAccountDisplay() {
        this.isValidCompanyApiKeySet = false;
        // const linkHtml = `${this.translateService.instant('STRINGS.ACQUIRE_TASK_TRACKER_API_NUMBER')} <a href="https://www.advancedscoreboard.com/tracker/setup.php?box=api-setup" target="_blank">${this.translateService.instant('STRINGS.HERE')}</a>`;
        // this.setIsUnableToFetchData('STRINGS.PROVIDE_TASK_TRACKER_API_NUMBER', linkHtml);
        this.setIsUnableToFetchData('SPECIAL_MSG.PROVIDE_STRIKE_GUARD_ID');
        this.resetBusy();
    }

    private isDateBeforeNow(date: Date): boolean {
        const nowUtc = moment.utc(); // Current UTC time
        const inputDateUtc = moment.utc(date); // Input date in UTC

        return inputDateUtc.isBefore(nowUtc); // Check if the input date is before now
    }
}
