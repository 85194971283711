import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { AddFavoriteLocationDlgComponent } from './dashboard/widgets/widget-green-sight/add-favorite-location-dlg/add-favorite-location-dlg.component';
import { AssetStatusIconComponent } from './dashboard/widgets/equipment-tracking/asset-status-icon/asset-status-icon.component';
import { AssetTrackingIntegrationComponent } from './dashboard/setup-wizard/step-integrations/asset-tracking-integration/asset-tracking-integration.component';
import { AssetTypeIconComponent } from './dashboard/widgets/equipment-tracking/asset-type-icon/asset-type-icon.component';
import { CoreRoutingModule } from './core-routing.module';
import { CourseTrakkDatumComponent } from './dashboard/widgets/turf-cloud/widget-turf-cloud-course-trakk/course-trakk-datum/course-trakk-datum.component';
import { DailyForecastCardComponent } from './dashboard/widgets/widget-local-weather-forecast/daily-forecast-card/daily-forecast-card.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardWidgetAlertBannerComponent } from './dashboard/widgets/_dashboard-widget-alert-banner/dashboard-widget-alert-banner.component';
import { DashboardWidgetContainerComponent } from './dashboard/widgets/_dashboard-widget-container/dashboard-widget-container.component';
import { DashboardWidgetHeaderComponent } from './dashboard/widgets/_dashboard-widget-header/dashboard-widget-header.component';
import { DataSettingsDlgComponent } from './dashboard/widgets/_common/data-settings-dlg/data-settings-dlg.component';
import { DecisionTreeBreakdownItemComponent } from './dashboard/widgets/decision-tree/decision-tree-breakdown-item/decision-tree-breakdown-item.component';
import { DecisionTreeDatumRowComponent } from './dashboard/widgets/decision-tree/decision-tree-datum-row/decision-tree-datum-row.component';
import { DtnDailyForecastCardComponent } from './dashboard/widgets/widget-dtn-weather-forecast/dtn-daily-forecast-card/dtn-daily-forecast-card.component';
import { EditorModule } from 'primeng/editor';
import { EnableWidgetGroupComponent } from './dashboard/setup-wizard/step-enabling/enable-widget-group/enable-widget-group.component';
import { EnableWidgetToggleComponent } from './dashboard/setup-wizard/step-enabling/enable-widget-toggle/enable-widget-toggle.component';
import { EquipmentTrackingDlgComponent } from './dashboard/widgets/equipment-tracking/equipment-tracking-dlg/equipment-tracking-dlg.component';
import { EzLocatorDesktopModalComponent } from './dashboard/widgets/ezLocator/ez-locator-desktop-modal/ez-locator-desktop-modal.component';
import { EzLocatorFmbPlacementComponent } from './dashboard/widgets/ezLocator/ez-locator-desktop-modal/ez-locator-fmb-placement/ez-locator-fmb-placement.component';
import { EzLocatorIntegrationComponent } from './dashboard/setup-wizard/step-integrations/ez-locator-integration/ez-locator-integration.component';
import { EzLocatorLeftRightPlacementComponent } from './dashboard/widgets/ezLocator/ez-locator-desktop-modal/ez-locator-left-right-placement/ez-locator-left-right-placement.component';
import { EzLocatorPinPlacementSummaryComponent } from './dashboard/widgets/ezLocator/ez-locator-pin-placement-summary/ez-locator-pin-placement-summary.component';
import { FrostRiskChartDlgComponent } from './dashboard/widgets/frost-risk/frost-risk-chart-dlg/frost-risk-chart-dlg.component';
import { GcsaaArticleModalComponent } from './dashboard/widgets/gcsaa-newsfeed/gcsaa-article-modal/gcsaa-article-modal.component';
import { GcsaaFeedItemCardComponent } from './dashboard/widgets/gcsaa-newsfeed/gcsaa-feed-item-card/gcsaa-feed-item-card.component';
import { GeoFencingDlgComponent } from './dashboard/widgets/geo-fencing/geo-fencing-dlg/geo-fencing-dlg.component';
import { GreenSiteMiniMapComponent } from './dashboard/widgets/widget-green-sight/green-site-mini-map/green-site-mini-map.component';
import { GridsterModule } from 'angular-gridster2';
import { InputSwitchModule } from 'primeng/inputswitch';
import { IntegrationCardComponent } from './dashboard/setup-wizard/step-integrations/integration-card/integration-card.component';
import { LastUpdatedComponent } from './dashboard/widgets/_common/last-updated/last-updated.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LynxCurrentWeatherCardComponent } from './dashboard/widgets/lynx/widget-lynx-local-current-weather/lynx-current-weather-card/lynx-current-weather-card.component';
import { LynxPumpStationCardComponent } from './dashboard/widgets/lynx/widget-lynx-pump-stations/lynx-pump-station-card/lynx-pump-station-card.component';
import { LynxPumpStationGaugeComponent } from './dashboard/widgets/lynx/lynx-pump-station-gauge/lynx-pump-station-gauge.component';
import { LynxStationStatusCardComponent } from './dashboard/widgets/lynx/widget-lynx-cloud/lynx-station-status-card/lynx-station-status-card.component';
import { MainContentContainerComponent } from './main-content-container/main-content-container.component';
import { MiniModeWidgetModalComponent } from './dashboard/widgets/_common/mini-mode-widget-modal/mini-mode-widget-modal.component';
import { MyTurfDetailsDlgComponent } from './dashboard/widgets/my-turf/my-turf-details-dlg/my-turf-details-dlg.component';
import { MyTurfIntegrationComponent } from './dashboard/setup-wizard/step-integrations/my-turf-integration/my-turf-integration.component';
import { NoStationsRunningComponent } from './dashboard/widgets/lynx/widget-lynx-running-stations/no-stations-running/no-stations-running.component';
import { NsnConnectDeviceCardComponent } from './dashboard/widgets/nsn/widget-nsn/nsn-connect-device-card/nsn-connect-device-card.component';
import { PerryWeatherChartComponent } from './dashboard/widgets/perry-weather/perry-weather-chart/perry-weather-chart.component';
import { PerryWeatherIntegrationComponent } from './dashboard/setup-wizard/step-integrations/perry-weather-integration/perry-weather-integration.component';
import { PerryWeatherTabbedChartComponent } from './dashboard/widgets/perry-weather/perry-weather-tabbed-chart/perry-weather-tabbed-chart.component';
import { PlaybooksChartWrapperComponent } from './dashboard/widgets/widget-playbooks/charts/playbooks-chart-wrapper/playbooks-chart-wrapper.component';
import { PlaybooksGddChartComponent } from './dashboard/widgets/widget-playbooks/charts/playbooks-gdd-chart/playbooks-gdd-chart.component';
import { PlaybooksIntegrationComponent } from './dashboard/setup-wizard/step-integrations/playbooks-integration/playbooks-integration.component';
import { PlaybooksNutrientChartComponent } from './dashboard/widgets/widget-playbooks/charts/playbooks-nutrient-chart/playbooks-nutrient-chart.component';
import { PogoGreenViewerComponent } from './dashboard/widgets/pogo/pogo-green-viewer/pogo-green-viewer.component';
import { PogoModalComponent } from './dashboard/widgets/pogo/pogo-modal/pogo-modal.component';
import { PogoZoneCardComponent } from './dashboard/widgets/pogo/pogo-zone-card/pogo-zone-card.component';
import { PogoZoneTabCardComponent } from './dashboard/widgets/pogo/pogo-zone-tab-card/pogo-zone-tab-card.component';
import { PumpStationDetailsDlgComponent } from './dashboard/widgets/lynx/widget-lynx-pump-station/pump-station-details-dlg/pump-station-details-dlg.component';
import { PwDataCardComponent } from './dashboard/widgets/perry-weather/pw-data-card/pw-data-card.component';
import { RunningStationsDetailsDlgComponent } from './dashboard/widgets/lynx/widget-lynx-running-stations/running-stations-details-dlg/running-stations-details-dlg.component';
import { RunningStationsExpandoPanelComponent } from './dashboard/widgets/lynx/widget-lynx-running-stations/running-stations-details-dlg/running-stations-expando-panel/running-stations-expando-panel.component';
import { SetupWizardComponent } from './dashboard/setup-wizard/setup-wizard.component';
import { SharedModule } from '../shared/shared.module';
import { SoilScoutBarChartComponent } from './dashboard/widgets/soil-scout/soil-scout-bar-chart/soil-scout-bar-chart.component';
import { SoilScoutGaugeComponent } from './dashboard/widgets/soil-scout/soil-scout-gauge/soil-scout-gauge.component';
import { SoilScoutIntegrationComponent } from './dashboard/setup-wizard/step-integrations/soil-scout-integration/soil-scout-integration.component';
import { SoilScoutLineChartComponent } from './dashboard/widgets/soil-scout/soil-scout-line-chart/soil-scout-line-chart.component';
import { SoilScoutLoginDlgComponent } from './dashboard/widgets/soil-scout/soil-scout-login-dlg/soil-scout-login-dlg.component';
import { SoilScoutModalComponent } from './dashboard/widgets/soil-scout/soil-scout-modal/soil-scout-modal.component';
import { SoilScoutOverlayDatumComponent } from './dashboard/widgets/soil-scout/soil-scout-overlay-datum/soil-scout-overlay-datum.component';
import { SoilScoutThresholdsComponent } from './dashboard/widgets/soil-scout/soil-scout-thresholds/soil-scout-thresholds.component';
import { SoilScoutThresholdsDlgComponent } from './dashboard/widgets/soil-scout/soil-scout-thresholds-dlg/soil-scout-thresholds-dlg.component';
import { SourceLegendCardComponent } from './dashboard/widgets/weather-averages/source-legend-card/source-legend-card.component';
import { SpecGridCardComponent } from './dashboard/widgets/spectrum/spec-grid-card/spec-grid-card.component';
import { SpecModalComponent } from './dashboard/widgets/spectrum/spec-modal/spec-modal.component';
import { SpecRangeSelectorBtnComponent } from './dashboard/widgets/spectrum/spec-range-selector/spec-range-selector-btn/spec-range-selector-btn.component';
import { SpecRangeSelectorComponent } from './dashboard/widgets/spectrum/spec-range-selector/spec-range-selector.component';
import { SpecTableCardComponent } from './dashboard/widgets/spectrum/spec-table-card/spec-table-card.component';
import { SpectrumIntegrationComponent } from './dashboard/setup-wizard/step-integrations/spectrum-integration/spectrum-integration.component';
import { StationStatusesDlgComponent } from './dashboard/widgets/lynx/widget-lynx-cloud/station-statuses-dlg/station-statuses-dlg.component';
import { StepActionComponent } from './dashboard/setup-wizard/step-integrations/step-action/step-action.component';
import { StepEnablingComponent } from './dashboard/setup-wizard/step-enabling/step-enabling.component';
import { StepFaqComponent } from './dashboard/setup-wizard/step-faq/step-faq.component';
import { StepGroupComponent } from './dashboard/setup-wizard/step-integrations/step-group/step-group.component';
import { StepIntegrationsComponent } from './dashboard/setup-wizard/step-integrations/step-integrations.component';
import { StrikeGuardApiKeyDialogComponent } from './dashboard/widgets/strike-guard/strike-guard-api-key-dialog/strike-guard-api-key-dialog.component';
import { StrikeGuardIntegrationComponent } from './dashboard/setup-wizard/step-integrations/strike-guard-integration/strike-guard-integration.component';
import { TaskTrackerApiKeyDialogComponent } from './dashboard/widgets/task-tracker/task-tracker-api-key-dialog/task-tracker-api-key-dialog.component';
import { TaskTrackerDailyMeasurementItemComponent } from './dashboard/widgets/task-tracker/widget-task-tracker-measurements/task-tracker-daily-measurement-item/task-tracker-daily-measurement-item.component';
import { TaskTrackerDailySafetyItemComponent } from './dashboard/widgets/task-tracker/widget-task-tracker-safety/task-tracker-daily-safety-item/task-tracker-daily-safety-item.component';
import { TaskTrackerIntegrationComponent } from './dashboard/setup-wizard/step-integrations/task-tracker-integration/task-tracker-integration.component';
import { TaskTrackerLaborSummaryChartComponent } from './dashboard/widgets/task-tracker/widget-task-tracker-labor/task-tracker-labor-summary-chart/task-tracker-labor-summary-chart.component';
import { ToroWidgetButtonComponent } from './dashboard/widgets/_common/toro-widget-button/toro-widget-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { TurfGuardAlertsHeaderComponent } from './dashboard/widgets/turf-guard/turf-guard-alerts-header/turf-guard-alerts-header.component';
import { TurfGuardChartComponent } from './dashboard/widgets/turf-guard/turf-guard-chart/turf-guard-chart.component';
import { TurfGuardChartDlgComponent } from './dashboard/widgets/turf-guard/turf-guard-chart-dlg/turf-guard-chart-dlg.component';
import { TurfGuardMiniModeGaugeComponent } from './dashboard/widgets/turf-guard/turf-guard-mini-mode-gauge/turf-guard-mini-mode-gauge.component';
import { TurfGuardSensorsDlgComponent } from './dashboard/widgets/turf-guard/turf-guard-sensors-dlg/turf-guard-sensors-dlg.component';
import { WeatherAveragesChartComponent } from './dashboard/widgets/weather-averages/weather-averages-chart/weather-averages-chart.component';
import { WeatherAveragesDatumCardComponent } from './dashboard/widgets/weather-averages/weather-averages-datum-card/weather-averages-datum-card.component';
import { WeatherGraphChartComponent } from './dashboard/widgets/weather-graphs/weather-graph-chart/weather-graph-chart.component';
import { WeatherGraphChartDlgComponent } from './dashboard/widgets/weather-graphs/weather-graph-chart-dlg/weather-graph-chart-dlg.component';
import { WeatherGraphComponent } from './dashboard/widgets/weather-graphs/weather-graph/weather-graph.component';
import { WidgetDecisionTreeComponent } from './dashboard/widgets/decision-tree/widget-decision-tree/widget-decision-tree.component';
import { WidgetDtnWeatherForecastComponent } from './dashboard/widgets/widget-dtn-weather-forecast/widget-dtn-weather-forecast.component';
import { WidgetEquipmentTrackingComponent } from './dashboard/widgets/equipment-tracking/widget-equipment-tracking/widget-equipment-tracking.component';
import { WidgetEvapotranspirationComponent } from './dashboard/widgets/widget-evapotranspiration/widget-evapotranspiration.component';
import { WidgetEzLocatorComponent } from './dashboard/widgets/ezLocator/widget-ez-locator/widget-ez-locator.component';
import { WidgetFrostWarningComponent } from './dashboard/widgets/frost-risk/widget-frost-warning/widget-frost-warning.component';
import { WidgetGcsaaAltComponent } from './dashboard/widgets/gcsaa-newsfeed-alt/widget-gcsaa-alt.component';
import { WidgetGcsaaComponent } from './dashboard/widgets/gcsaa-newsfeed/widget-gcsaa.component';
import { WidgetGeoFencingComponent } from './dashboard/widgets/geo-fencing/widget-geo-fencing/widget-geo-fencing.component';
import { WidgetGreenSightComponent } from './dashboard/widgets/widget-green-sight/widget-green-sight.component';
import { WidgetLocalWeatherForecastComponent } from './dashboard/widgets/widget-local-weather-forecast/widget-local-weather-forecast.component';
import { WidgetLynxCloudComponent } from './dashboard/widgets/lynx/widget-lynx-cloud/widget-lynx-cloud.component';
import { WidgetLynxLocalCurrentWeatherComponent } from './dashboard/widgets/lynx/widget-lynx-local-current-weather/widget-lynx-local-current-weather.component';
import { WidgetLynxLocalWeatherGraphsComponent } from './dashboard/widgets/lynx/widget-lynx-local-weather-graphs/widget-lynx-local-weather-graphs.component';
import { WidgetLynxPumpStationComponent } from './dashboard/widgets/lynx/widget-lynx-pump-station/widget-lynx-pump-station.component';
import { WidgetLynxPumpStationsComponent } from './dashboard/widgets/lynx/widget-lynx-pump-stations/widget-lynx-pump-stations.component';
import { WidgetLynxPumpStationsWithGaugesComponent } from './dashboard/widgets/lynx/widget-lynx-pump-stations-with-gauges/widget-lynx-pump-stations-with-gauges.component';
import { WidgetLynxRunningStationsComponent } from './dashboard/widgets/lynx/widget-lynx-running-stations/widget-lynx-running-stations.component';
import { WidgetMyTurfMaintenanceComponent } from './dashboard/widgets/my-turf/widget-my-turf-maintenance/widget-my-turf-maintenance.component';
import { WidgetMyTurfOrdersComponent } from './dashboard/widgets/my-turf/widget-my-turf-orders/widget-my-turf-orders.component';
import { WidgetNotesComponent } from './dashboard/widgets/widget-notes/widget-notes.component';
import { WidgetNsnComponent } from './dashboard/widgets/nsn/widget-nsn/widget-nsn.component';
import { WidgetPerryWeatherComponent } from './dashboard/widgets/perry-weather/widget-perry-weather/widget-perry-weather.component';
import { WidgetPlaybooksComponent } from './dashboard/widgets/widget-playbooks/widget-playbooks.component';
import { WidgetPogoComponent } from './dashboard/widgets/pogo/widget-pogo/widget-pogo.component';
import { WidgetSoilScoutMapComponent } from './dashboard/widgets/soil-scout/widget-soil-scout-map/widget-soil-scout-map.component';
import { WidgetSoilScoutMobileComponent } from './dashboard/widgets/soil-scout/widget-soil-scout-mobile/widget-soil-scout-mobile.component';
import { WidgetSoilScoutMoistureComponent } from './dashboard/widgets/soil-scout/widget-soil-scout-moisture/widget-soil-scout-moisture.component';
import { WidgetSoilScoutSalinityComponent } from './dashboard/widgets/soil-scout/widget-soil-scout-salinity/widget-soil-scout-salinity.component';
import { WidgetSoilScoutTemperatureComponent } from './dashboard/widgets/soil-scout/widget-soil-scout-temperature/widget-soil-scout-temperature.component';
import { WidgetSpectrumMoistureComponent } from './dashboard/widgets/spectrum/widget-spectrum-moisture/widget-spectrum-moisture.component';
import { WidgetStrikeGuardComponent } from './dashboard/widgets/strike-guard/widget-strike-guard/widget-strike-guard.component';
import { WidgetTaskTrackerLaborComponent } from './dashboard/widgets/task-tracker/widget-task-tracker-labor/widget-task-tracker-labor.component';
import { WidgetTaskTrackerMeasurementsComponent } from './dashboard/widgets/task-tracker/widget-task-tracker-measurements/widget-task-tracker-measurements.component';
import { WidgetTaskTrackerSafetyComponent } from './dashboard/widgets/task-tracker/widget-task-tracker-safety/widget-task-tracker-safety.component';
import { WidgetTaskTrackerTurfRadComponent } from './dashboard/widgets/task-tracker/widget-task-tracker-turf-rad/widget-task-tracker-turf-rad.component';
import { WidgetTestComponent } from './dashboard/widgets/widget-test/widget-test.component';
import { WidgetTurfCloudCourseTrakkComponent } from './dashboard/widgets/turf-cloud/widget-turf-cloud-course-trakk/widget-turf-cloud-course-trakk.component';
import { WidgetTurfCloudLaborStatsComponent } from './dashboard/widgets/turf-cloud/widget-turf-cloud-labor-stats/widget-turf-cloud-labor-stats.component';
import { WidgetTurfGuardMoistureComponent } from './dashboard/widgets/turf-guard/widget-turf-guard-moisture/widget-turf-guard-moisture.component';
import { WidgetTurfGuardSalinityComponent } from './dashboard/widgets/turf-guard/widget-turf-guard-salinity/widget-turf-guard-salinity.component';
import { WidgetTurfGuardTemperatureComponent } from './dashboard/widgets/turf-guard/widget-turf-guard-temperature/widget-turf-guard-temperature.component';
import { WidgetWeatherAveragesComponent } from './dashboard/widgets/weather-averages/widget-weather-averages/widget-weather-averages.component';
import { WidgetWeatherGraphsComponent } from './dashboard/widgets/weather-graphs/widget-weather-graphs/widget-weather-graphs.component';
import { WidgetWeatherRadarComponent } from './dashboard/widgets/widget-weather-radar/widget-weather-radar.component';

@NgModule({
    declarations: [
        DashboardComponent,
        MainContentContainerComponent,
        WidgetTestComponent,
        DashboardWidgetContainerComponent,
        DashboardWidgetHeaderComponent,
        WidgetMyTurfOrdersComponent,
        WidgetMyTurfMaintenanceComponent,
        WidgetEquipmentTrackingComponent,
        WidgetWeatherRadarComponent,
        WidgetTurfGuardSalinityComponent,
        WidgetTurfGuardMoistureComponent,
        WidgetTurfGuardTemperatureComponent,
        WidgetFrostWarningComponent,
        WidgetEvapotranspirationComponent,
        WidgetTurfCloudCourseTrakkComponent,
        WidgetTurfCloudLaborStatsComponent,
        WidgetWeatherGraphsComponent,
        WidgetLynxPumpStationComponent,
        WidgetLynxRunningStationsComponent,
        WidgetGreenSightComponent,
        WidgetLocalWeatherForecastComponent,
        DashboardWidgetAlertBannerComponent,
        RunningStationsDetailsDlgComponent,
        RunningStationsExpandoPanelComponent,
        CourseTrakkDatumComponent,
        PumpStationDetailsDlgComponent,
        TurfGuardChartComponent,
        TurfGuardChartDlgComponent,
        TurfGuardSensorsDlgComponent,
        TurfGuardAlertsHeaderComponent,
        MyTurfDetailsDlgComponent,
        WeatherGraphComponent,
        WeatherGraphChartDlgComponent,
        WeatherGraphChartComponent,
        EquipmentTrackingDlgComponent,
        AssetTypeIconComponent,
        AssetStatusIconComponent,
        FrostRiskChartDlgComponent,
        GreenSiteMiniMapComponent,
        AddFavoriteLocationDlgComponent,
        DataSettingsDlgComponent,
        WidgetNsnComponent,
        LynxPumpStationGaugeComponent,
        WidgetLynxPumpStationsWithGaugesComponent,
        WidgetLynxLocalCurrentWeatherComponent,
        LynxCurrentWeatherCardComponent,
        WidgetLynxPumpStationsComponent,
        LynxPumpStationCardComponent,
        WidgetLynxLocalWeatherGraphsComponent,
        NsnConnectDeviceCardComponent,
        WidgetPlaybooksComponent,
        PlaybooksChartWrapperComponent,
        PlaybooksNutrientChartComponent,
        PlaybooksGddChartComponent,
        LastUpdatedComponent,
        WidgetTaskTrackerLaborComponent,
        WidgetTaskTrackerMeasurementsComponent,
        WidgetTaskTrackerSafetyComponent,
        TaskTrackerLaborSummaryChartComponent,
        TaskTrackerDailyMeasurementItemComponent,
        TaskTrackerDailySafetyItemComponent,
        ToroWidgetButtonComponent,
        TaskTrackerApiKeyDialogComponent,
        NoStationsRunningComponent,
        WidgetDecisionTreeComponent,
        DecisionTreeDatumRowComponent,
        DecisionTreeBreakdownItemComponent,
        MiniModeWidgetModalComponent,
        TurfGuardMiniModeGaugeComponent,
        DailyForecastCardComponent,
        WidgetEzLocatorComponent,
        EzLocatorDesktopModalComponent,
        EzLocatorLeftRightPlacementComponent,
        EzLocatorFmbPlacementComponent,
        EzLocatorPinPlacementSummaryComponent,
        WidgetLynxCloudComponent,
        LynxStationStatusCardComponent,
        StationStatusesDlgComponent,
        SetupWizardComponent,
        StepIntegrationsComponent,
        IntegrationCardComponent,
        MyTurfIntegrationComponent,
        StepGroupComponent,
        EzLocatorIntegrationComponent,
        AssetTrackingIntegrationComponent,
        StepActionComponent,
        PlaybooksIntegrationComponent,
        TaskTrackerIntegrationComponent,
        StepEnablingComponent,
        EnableWidgetGroupComponent,
        EnableWidgetToggleComponent,
        StepFaqComponent,
        WidgetGeoFencingComponent,
        GeoFencingDlgComponent,
        WidgetPerryWeatherComponent,
        PwDataCardComponent,
        WidgetGcsaaComponent,
        GcsaaFeedItemCardComponent,
        GcsaaArticleModalComponent,
        WidgetGcsaaAltComponent,
        WidgetWeatherAveragesComponent,
        WidgetSoilScoutMoistureComponent,
        WeatherAveragesDatumCardComponent,
        WeatherAveragesChartComponent,
        SourceLegendCardComponent,
        PerryWeatherChartComponent,
        WidgetPogoComponent,
        PogoZoneTabCardComponent,
        PogoZoneCardComponent,
        PogoGreenViewerComponent,
        PogoModalComponent,
        PerryWeatherTabbedChartComponent,
        PerryWeatherIntegrationComponent,
        WidgetSoilScoutSalinityComponent,
        WidgetSoilScoutTemperatureComponent,
        SoilScoutGaugeComponent,
        SoilScoutModalComponent,
        SoilScoutBarChartComponent,
        WidgetTaskTrackerTurfRadComponent,
        WidgetSoilScoutMobileComponent,
        SoilScoutLineChartComponent,
        SoilScoutLoginDlgComponent,
        SoilScoutThresholdsDlgComponent,
        SoilScoutThresholdsComponent,
        SoilScoutIntegrationComponent,
        WidgetSoilScoutMapComponent,
        SoilScoutOverlayDatumComponent,
        WidgetNotesComponent,
        WidgetDtnWeatherForecastComponent,
        DtnDailyForecastCardComponent,
        WidgetSpectrumMoistureComponent,
        SpecGridCardComponent,
        SpecModalComponent,
        SpecRangeSelectorComponent,
        SpecRangeSelectorBtnComponent,
        SpecTableCardComponent,
        SpectrumIntegrationComponent,
        WidgetStrikeGuardComponent,
        StrikeGuardApiKeyDialogComponent,
        StrikeGuardIntegrationComponent,
    ],
    imports: [
        SharedModule,
        CommonModule,
        CoreRoutingModule,
        TranslateModule,
        GridsterModule,
        LeafletModule,
        InputSwitchModule,
        AccordionModule,
        EditorModule,
    ],
    providers: [
        DatePipe
    ],
    exports: [
        SetupWizardComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class CoreModule {
}
