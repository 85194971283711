import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'toro-strike-guard-api-key-dialog',
    templateUrl: './strike-guard-api-key-dialog.component.html',
    styleUrls: ['./strike-guard-api-key-dialog.component.less']
})
export class StrikeGuardApiKeyDialogComponent {

    @Output() setApiKey = new EventEmitter<string>();
    @Output() cancelClick = new EventEmitter();

    @Input() apiKey: string;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onSetApiKey() {
        this.setApiKey.emit(this.apiKey);
    }

    onCancelDialog() {
        this.cancelClick.emit();
    }

}
