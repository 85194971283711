import { Observable, of } from 'rxjs';
import { AppInjector } from '../../demo/demo.module';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { SpecFieldScoutCollections } from './models/spec-fs-collections.model';
import { SpecFieldScoutDataResponse } from './models/spec-field-scout-data-response.model';
import { SpecFsGridData } from './models/spec-fs-grid-data.model';
import { SpecFsProfile } from './models/spec-fs-profile.model';
import { SpectrumApiService } from './spectrum-api.service';

@Injectable({
    providedIn: 'root'
})
export class SpectrumManagerService {

    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    constructor(private spectrumApiService: SpectrumApiService) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getFieldScoutData(apiKey: string, deviceType: string, startDate: Date, endDate: Date): Observable<SpecFieldScoutDataResponse> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.specFieldScoutData) ; }

        return this.spectrumApiService.getFieldScoutData(apiKey, deviceType, startDate, endDate);
    }

    getFieldScoutProfile(apiKey: string, profileId = 0): Observable<SpecFsProfile> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.specFieldScoutProfile) ; }

        return this.spectrumApiService.getFieldScoutProfile(apiKey, profileId);
    }

    getFieldScoutGridData(apiKey: string, gridId: number): Observable<SpecFsGridData> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.specFieldScoutGridData) ; }

        return this.spectrumApiService.getFieldScoutGridData(apiKey, gridId);
    }

    getFieldScoutCollections(apiKey: string): Observable<SpecFieldScoutCollections> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.specFieldScoutCollections); }

        return this.spectrumApiService.getFieldScoutCollections(apiKey);
    }
}
