import { Observable, of } from 'rxjs';
import { AppInjector } from '../../demo/demo.module';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { StrikeGuardApiService } from './strike-guard-api.service';
import { StrikeGuardDatum } from './models/strike-guard-datum.model';

@Injectable({
    providedIn: 'root'
})
export class StrikeGuardManagerService {

    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    constructor(private strikeGuardApiService: StrikeGuardApiService) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getEntryById(sgId: string): Observable<StrikeGuardDatum> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.strikeGuardData) ; }

        return this.strikeGuardApiService.getEntryById(sgId);
    }
}
