import StrikeGuardStatus = ToroEnums.StrikeGuardStatus;

import * as moment from 'moment';
import { ToroEnums } from '../../../common/enumerations/toro.enums';

export class StrikeGuardDatum {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.nextModeChange) this.nextModeChange = moment.utc(json.nextModeChange).toDate();
            if (json.lastContactUtc) this.lastContactUtc = moment.utc(json.lastContactUtc).toDate();
        }
    }

    sgId: string;
    locationName: string;
    currentMode: StrikeGuardStatus;
    nextModeChange: Date;
    lastContactUtc: Date;
}
