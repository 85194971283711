import { ApiService, HttpMethod } from '../_common/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Note } from './models/note.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NoteApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getNote(userId: string, siteId: number): Observable<Note> {
        return this.apiService.apiRequest<any>(NoteApiService.getNoteUrl(userId, siteId), HttpMethod.Get)
            .pipe(map(result => new Note(result)));
    }

    updateNote(note: Note): Observable<any> {
        return this.apiService.apiRequest<any>(NoteApiService.updateNoteUrl, HttpMethod.Put, note)
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}note`; }

    private static getNoteUrl(userId: string, siteId: number): string { return `${NoteApiService.baseUrl}?userId=${userId}&siteId=${siteId}`; }

    private static get  updateNoteUrl(): string { return `${NoteApiService.baseUrl}`; }
}
