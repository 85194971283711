<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [spinnerText]="spinnerText"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [menuStyleClass]="'toro-widget-menu sg-context-menu'"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [showEmptyFooter]="false"
            [isMiniModeWidget]="true"
            (gotoSite)="onNavigateToSite()"
    >

        <div class="toro-decision-tree-contentX toro-flex-child mini-mode-widget-container">

            <div class="toro-widget-content-pane prevent-select">
                <ng-container [ngTemplateOutlet]="strikeGuard" [ngTemplateOutletContext]="{isMobile: true}"></ng-container>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [spinnerText]="spinnerText"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [menuStyleClass]="'toro-widget-menu sg-context-menu'"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            (gotoSite)="onNavigateToSite()"
    >

        <div class="toro-flex-child prevent-select">
            <ng-container [ngTemplateOutlet]="strikeGuard"></ng-container>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <img id="tid-sg-title-icon" src="../../../../../assets/images/strike-guard-logo.png" alt="">
</ng-template>

<ng-template #strikeGuard let-isMobile="isMobile">
    <div class="sg-content-container" style="position: relative;" [style.background-color]="backgroundColor" [ngClass]="{'is-mobile': isMobile}">
<!--        <div style="position: absolute; top: 2px; left: 2px;">Location: {{ strikeGuardDatum?.locationName }}</div>-->
        <ng-container [ngTemplateOutlet]="statusBanner"></ng-container>

        <div id="sg-status-details" *ngIf="messageText != null || modeText != null" [ngClass]="{'offline': strikeGuardDatum?.currentMode == StrikeGuardStatus.Offline}">
            <div *ngIf="messageText != null" id="sg-min-banner" class="banner">
                <div id="sg-min-time">{{ deltaMins }}</div>
                <div id="sg-min-text">{{ messageText | translate }}</div>
            </div>

            <div *ngIf="modeText != null" id="sg-mode-banner" class="banner" [ngClass]="{'no-msg-txt': messageText == null}">
                {{ modeText | translate }}
                <span id="sg-mode-time">{{ modeTime }}</span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #statusBanner>
    <div id="sg-status-banner"
         class="banner"
         [style.color]="statusColor"
         [ngClass]="{'solo-banner': isSoloBanner, 'failure': strikeGuardDatum?.currentMode == StrikeGuardStatus.Error}"
    >
        <div id="sg-sb-content" [ngClass]="{'no-lightning': strikeGuardDatum?.currentMode == StrikeGuardStatus.NoLightingDetected}">
            <fa-icon *ngIf="strikeGuardDatum?.currentMode != StrikeGuardStatus.Error" [icon]="statusIcon"></fa-icon>
            <div id="sg-status-text">{{ statusText | translate }}</div>
        </div>
        <div id="sg-sub-content" *ngIf="strikeGuardDatum?.currentMode == StrikeGuardStatus.Error">
            {{ 'SPECIAL_MSG.STRIKE_GUARD_FAILURE' | translate }}
        </div>
    </div>
</ng-template>

<toro-strike-guard-api-key-dialog
        *ngIf="isApiKeyDialogDisplayed"
        [apiKey]="newStrikeGuardID"
        (setApiKey)="onSetApiKey($event)"
        (cancelClick)="isApiKeyDialogDisplayed = false"
></toro-strike-guard-api-key-dialog>
