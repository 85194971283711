<div id="step-wrapper">
    <div id="step-container"  [ngClass]="{'is-do-setup': _isDoSetup}">
        <toro-integration-card
                *ngFor="let i of integrations"
                [integration]="i"
                (doSetup)="onDoSetup(i)"
        ></toro-integration-card>
    </div>

    <toro-waiting-indicator class="widget-non-draggable" *ngIf="!isConnectionCheckComplete && isBusy" [isVisible]="isBusy" text="{{spinnerText | translate}}..." backgroundColor="rgba(255,255,255, .9)">
    </toro-waiting-indicator>
</div>

<ng-container *ngIf="_isDoSetup" [ngTemplateOutlet]="setup"></ng-container>


<ng-template #setup [ngSwitch]="selectedWidgetType">
    <ng-container *ngSwitchCase="WidgetType.MyTurfMaintenance" [ngTemplateOutlet]="myTurf"></ng-container>
    <ng-container *ngSwitchCase="WidgetType.PerryWeather" [ngTemplateOutlet]="perryWeather"></ng-container>
    <ng-container *ngSwitchCase="WidgetType.EquipmentTracking" [ngTemplateOutlet]="assetTracking"></ng-container>
    <ng-container *ngSwitchCase="WidgetType.Playbooks" [ngTemplateOutlet]="playBooks"></ng-container>
    <ng-container *ngSwitchCase="WidgetType.TaskTrackerSafety" [ngTemplateOutlet]="taskTracker"></ng-container>
    <ng-container *ngSwitchCase="WidgetType.EzLocator" [ngTemplateOutlet]="ezLocator"></ng-container>
    <ng-container *ngSwitchCase="WidgetType.SoilScoutMoisture" [ngTemplateOutlet]="soilScout"></ng-container>
    <ng-container *ngSwitchCase="WidgetType.SpectrumMoisture" [ngTemplateOutlet]="spectrum"></ng-container>
    <ng-container *ngSwitchCase="WidgetType.StrikeGuard" [ngTemplateOutlet]="strikeGuard"></ng-container>
</ng-template>

<ng-template #myTurf>
    <div class="wiz-integration-container">
        <toro-my-turf-integration
                (canConnect)="onCanConnect($event)"
                (configured)="onConfigured($event)"
        ></toro-my-turf-integration>
    </div>
</ng-template>

<ng-template #perryWeather>
    <div class="wiz-integration-container" style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
        <toro-perry-weather-integration
                [(settings)]="integrations[WizardIntegration.PerryWeather].settings"
                (canConnect)="onCanConnect($event)"
                (configured)="onConfigured($event)"
        ></toro-perry-weather-integration>
    </div>
</ng-template>

<ng-template #assetTracking>
    <div class="wiz-integration-container">
        <toro-asset-tracking-integration
                [(settings)]="integrations[WizardIntegration.AssetTracking].settings"
                (canConnect)="onCanConnect($event)"
                (configured)="onConfigured($event)"
        ></toro-asset-tracking-integration>
    </div>
</ng-template>

<ng-template #playBooks>
    <div class="wiz-integration-container">
        <toro-playbooks-integration
                [(settings)]="integrations[WizardIntegration.Playbooks].settings"
                (canConnect)="onCanConnect($event)"
                (configured)="onConfigured($event)"
        ></toro-playbooks-integration>
    </div>
</ng-template>

<ng-template #taskTracker>
    <div class="wiz-integration-container">
        <toro-task-tracker-integration
                [(settings)]="integrations[WizardIntegration.TaskTracker].settings"
                (canConnect)="onCanConnect($event)"
                (configured)="onConfigured($event)"
        ></toro-task-tracker-integration>
    </div>
</ng-template>

<ng-template #ezLocator>
    <div class="wiz-integration-container">
        <toro-ez-locator-integration
                (canConnect)="onCanConnect($event)"
                (configured)="onConfigured($event)"
        ></toro-ez-locator-integration>
    </div>
</ng-template>

<ng-template #soilScout>
    <div class="wiz-integration-container" style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
        <toro-soil-scout-integration
                (canConnect)="onCanConnect($event)"
                (configured)="onConfigured($event)"
        ></toro-soil-scout-integration>
    </div>
</ng-template>

<ng-template #spectrum>
    <div class="wiz-integration-container" style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
        <toro-spectrum-integration
                [(settings)]="integrations[WizardIntegration.Spectrum].settings"
                (canConnect)="onCanConnect($event)"
                (configured)="onConfigured($event)"
        ></toro-spectrum-integration>
    </div>
</ng-template>

<ng-template #strikeGuard>
    <div class="wiz-integration-container" style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
        <toro-strike-guard-integration
                [(settings)]="integrations[WizardIntegration.StrikeGuard].settings"
                (canConnect)="onCanConnect($event)"
                (configured)="onConfigured($event)"
        ></toro-strike-guard-integration>
    </div>
</ng-template>



